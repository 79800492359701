header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    font-size: 16px;
}
.navbar {
    position: absolute;
    width: 100%;
    height: 76px;
    top: 0;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 3px solid #eee;
    align-items: center;
}

.navbar .container__logo{
    display: flex;
    flex-direction: column;
    font-size: 35px;
    margin-left: 30px;
}
.tagline {
    font-size: 15px;
}
.profile_details {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0087ee;

}
.profile_details__name {
    margin-left: 20px;
    font-size: 15px;
    color: blue;
}
.profile_details__signout {
    display: flex;

}
.myaccount {
    display: flex;
    width: 50%;
    align-items: flex-start;
}
.signout {
    display: flex;
    width: 50%;
    float: right;
    align-items: flex-end;
}
.navbar .container {
    height: 100%;
    display: flex;
    flex: 75%;
    justify-content: space-between;
    align-items: center;
}

.navbar span {
    color: #5651e5;
}
.navbar .navmenu__item {
    color: #5651e5;
}

.navbar .nav-menu {
    display: flex;
     list-style-type: none;

}
.how__we__diffrent{
    background-color: #c3c0f7;
    color: black;
}
.navbar .signin__button {
    display: flex;
    flex: 10%;
    align-items: center;
}
.signin__button button {
    padding:10px 24px;
     border: 2px solid #0087ee;
    border-radius: 5px;
    -webkit-transition: opacity .2s,color .2s,border-color .2s,background-color .2s;
    transition: opacity .2s,color .2s,border-color .2s,background-color .2s;
    text-align: center;
    font-weight: 900;
    background-color: transparent;  
    color: #0087ee;

}
.after__signin{
      color:white;
      align-items: center;
      font-size: 25px;
}
.signin__button__mobile{
    display: none;
}

.navbar li {
    padding: 1rem;
}

.hamburger {
    display: none;
}

.navbar .icon {
    font-size: 1.5rem;
}
ul {
  list-style-type: none;
}

.navmenu__item a {
  position: relative;
  color: #5651e5;
  text-decoration: none;
}
.navmenu__item a:visited {
  color: #5651e5;
  text-decoration: none;
}
 .navmenu__item a:hover {
  color: #000;
  text-decoration: none;
}
.navmenu__item a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -2px;
  left: 0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
li.active a:before,
.navmenu__item a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

@media screen and (max-width:940px) {
    .hamburger {
        display: block;
        margin-right: 20px;
        margin-left: 20px;
        font-size: 25px;

    }
 .navbar .container__logo{
    display: flex;
    font-size: 25px;
    flex: 5%;
    margin-left: 25px;
}

    .navbar .nav-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        top: 50px;
        right: -100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.98);
        transition: 0.4s ease-in-out;
        z-index: 1000;
        
    }

    .navbar .active {
        right: 0;
    }

    .navbar .nav-menu li a {
        font-size: 1rem;
    }
    .navbar .signin__button{
    color: #0087ee;
    font-weight: 700;
    text-align: center;
    }
    .signin__button button{
        display: none;
    }
    .signin__button__mobile{
    flex: 40%;
    display: block;
    padding: 10px 15px !important;
    margin-right: 30px;
    font-size: 10px;
    text-align: center;
    background-color: transparent;  
    color: #0087ee;

}
.after__signin{
      color:white;
      display: flex;
      align-items: center;
      padding: 10px 15px !important;
}


     .navbar .getQuote__btn  {
        display: none;
    }
}





