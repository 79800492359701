.hero_content {
    width: 100%;
    position: relative;

}
.hero__section {
   display: flex;
}
.hero_container{
   width: 50%;
}
 .hero__bgImage {
   width: 50%;
 }
 .bgImage {
    width: 100%;
    padding: 80px 0px 0px 0px;
 }
.hero_container .hero_container__section{
    box-sizing: border-box;
}
 .hero_container__section .hero_container__section1{
    padding: 100px 50px 50px 0px;
    width: 100%;

}
.hero_container__section1 .row{
    position: absolute;
    display: flex;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}
 .hero_container .hero_homepage{
    width: 50%;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 1;
   
 }
 .hero_container  .hero__landingheader h1{
    color: #0a304e;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 16px;
  
 }


#spin {
  color:#0e6aed;
}
#spin:after {
  content:"";
  animation: spin 13s linear infinite;
}
@keyframes spin {
  0% { content:"Industrial Manufacturing";}
  50% { content:"Industrial Hardwares";}
  100% { content:"Industrial RawMaterial";}

}

 
 .row .hero__landingtext__subheadline{
    color: #0c171f;
    font-size: 1.2rem;
    line-height: 23px;
    margin-bottom: 24px;
 }
 .row .hero__landingtext__symbol{
    display: flex;
    margin-bottom: 48px;
 }
 .row .hero__landingtext__symbol1{
    display: flex;
    margin-right: 14px;
    color: #22445f;
    align-items: center;
 }
 .fa_calender{
    font-weight:400
 }
 .hero__landingtext__symbol__time {
    font-size: 32px;
    margin-right: 12px;
    padding-top: 6px;
 }
 .hero__landingtext__symbol__text{
    max-width: 169px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
 }
 .row .hero__landingtext__symbol2{
    display: flex;
    margin-right: 14px;
    color: #22445f;
    align-items: center;
 }
 .hero__landingtext__symbol__quality {
    font-size: 32px;
    margin-right: 12px;
    padding-top: 6px;
}
.fa_shield{
    font-weight:400;
}
.hero__landing__logo{
    display: flex;
    cursor: pointer;
}
.instant__quote__button{
    display: table;
    border-radius: 4px;
    background-color: #0e6aed;
    color: #f5faff;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    padding: 12px 48px;
    
}
.secure__lock{
    font-size: 15px;
    font-weight: 600;
    color: #54646e;
}
.fa__lock{
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

/* mobilse section*/

@media screen and (max-width:940px){

.hero_content {
    width: 100%;

}
.hero__section {
   display: flex;
   flex-direction: column;
}
.hero_container{
   width: 100%;
   height: 400px;
}
 .hero__bgImage {
   width: 100%;
   margin-top:70px ;
   
 }
 .bgImage {
    width: 100%;
 }


.hero_container__section1 .hero_homepage{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 123;
   
 }
.hero_container  .hero__landingheader h1{
    color: #0a304e;
    font-size: 18px;
    font-weight: 1000;
    line-height: 35px;

}
 .row .hero__landingtext__symbol{
    display: flex;
    align-items: center;
 }
 .row .hero__landingtext__subheadline{
    color: #0c171f;
    font-size: 18px;
    line-height: 25px;
 }
 .row .hero__landingtext__symbol{
    display: flex;
 }
  .row .hero__landingtext__symbol1{
    display: flex;
    color: #22445f;
    align-items: center;
 }

  .hero__landingtext__symbol__text{
    max-width: 169px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
 }
   .hero__landingtext__symbol__text{
    max-width: 169px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
 }
.secure__lock{
    margin-top: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #54646e;
}

}