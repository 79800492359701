
.industrial__card{
    display: flex;
    justify-content: center;
    width: 100%;

}
.industrial__card .row {
    background-color:transparent;
    height: 500px;
}

.row .continer__row{
    display: flex;
    flex-wrap: wrap;   
    
}
.row .industrial__card__desktop{
    flex: 1 0 0 21%;
    margin: 20px;
}

.row .card__header{
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    border: 1px solid #8f9699;
    background-color: #fff;


}


.row .card__header__title {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 22px;
    height: 50px;
    background-color: #fff;
    color: #0a304e;
    text-align: center;
    padding: 10px 0px 10px 0px;
}
 .row .industrial__card__desktop .card__header__icon{
    width: 100%;
    height: 200px;
    text-align: center;
}
.row .industrial__card__desktop .card__header__bottom{
    font-size: 1.2rem;
    height: 200px;
    background-color: #fff;
    color: #000;
    text-align: center;
    padding: 10px 0px 10px 0px;

}
.industrial__other_services{
    margin-top: 650px;
}
.other_services_contianer{
    margin: 20px;
}
.other_services_row{
    display: grid;
    grid-template-columns: auto auto auto;
    
}
.other_services_row .card__header{
    padding: 20px;
    height: auto;
}

.other_services_row .card__header__icon{
    width: 400px;
    border-radius: 10px;
}
.other_services_row .card__header__title{
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    color: #0a304e;
    text-align: center;
    padding: 10px 0px 10px 0px;
}
.other_services_row .card__header__title{
   font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    color: #0a304e;
    text-align: center;
    margin-bottom: 7px;

}

.industrial__card__mobile{
    display: none;
}

@media screen and (max-width:940px) {
    .row .industrial__card__desktop{
        display: none;
    }
.industrial__card .row {
    height: 2300px;
    background-color:transparent;
    padding: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.industrial__card__mobile{
    width: 100%;
    display: flex;
    border-color: transparent;
    text-align: center;
    align-items: center;
    padding: 20px;
}

.row .card__header{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #8f9699;
    border-radius: 5px;
    background-color: #fff;
    transition: all 0.4s;
    overflow: hidden;
    align-items: center;
}

.row .card__header img {
  width: auto;
  height: auto;
  max-width: 100%;
}
.industrial__other_services{
    margin-top: 1750px;
}
.other_services_contianer{
    margin: 20px;
}
.other_services_row{
    display: grid;
    grid-template-columns: auto;
    text-align: center;
}
.other_services_row .card__header__icon{
  width: auto;
  height: auto;
  max-width: 100%;
}   
.card__header__bottom{
    font-size: 20px;
}
}