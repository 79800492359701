
.page__border {
    height: 100vh;

}
.pageRow1Aboutus {
    height: 1000px;
}
.pageRow2{
    height: 500px;
    display: flex;
    align-items: center;
    text-align: center;

}
.pageRow2Injection {
     padding: 30px;
    height: 600px;
    display: flex;
    text-align: center;
    align-items: center;



}
.pageRow3{
    margin-top: 76px;
    height: auto;
}
.pageRow3Injection {
    height: 300px;


}
.projectList {
    margin-bottom: 15px;
}

.projectLink{
    color: rgb(18, 175, 223); /* Initial color */
    text-decoration: none; /* Remove underline for links */
    transition: color 0.3s; /* Smooth transition for color change */
    margin-bottom: 10px;
}
.pageRow3__InnerDiv {
    padding: 20px;
    display: flex;
    text-align: center;
    justify-content: center;

}
.pageRowTitle {
    text-align: center;
    background-color: #000;
    text-align: center;
    margin-top: 30px;
    color: #ffF
}
.pageRowTitle_aboutUs {
    background-color: #000;
    text-align: center;
    margin-top: 10px;
    color: #fff;
}
.pageRowPartA {
    width: 100vw;
}

.projectTitleImage {
        width: 500px;
        height: 500px;
        padding: 10px;
}
.projectDescription {
        font-size: 20px;
        text-align: center;
        width: 600px;
        height: auto;
        text-align: center;
        padding: 10px;
}

.components {
    display: flex;
    margin-left: -20px;
    flex-direction: column;
    align-items: center;
}
.pageRowPartB {
    width: 50%;
    padding: 20px;

}
.rowHeading {
    padding: 30px;
    width: 50%;
}
.rowHeadingTagline {
    font-size: 1.5rem;
    padding-top: 30px;
}
.rowImage {
    width: 50%;
    text-align: end;
}
.twoDiv{
    display: flex;
    flex-direction: column;
}
.bestSellingPage {
    display: flex;
    flex-direction: column;
    height: 1000px;
  }
  .bestSellingGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .bestSellingCard{
    width: 100%;
    padding: 20px;
  }
  .bestSelling_images {
    width: 50%;
    height: 50%;
  }
@media screen  and (max-width:940px){
    .pageRow2 {
        display: flex;
        flex-direction: column;
        height: 600px;

    }
    .pageRow2Injection {
        display: flex;
        flex-direction: column;
         height: 700px;

}
.projectTitleImage {
    width: 250px;
    height: 250px;
    padding: 10px;
}
    .pageRow3 {
        height: auto;
    }
    .pageRow3__InnerDiv {
        display: flex;
        flex-direction: column;

    }
    .pageRowPartA {
        width: 100%;

    }
    .pageRowPartB {
        width: 100%;
        padding: 10px;

    }
    .rowHeading {
    padding: 20px;
    width: 100%;
   }
   .rowImage {
    width: 100%;
  }
  .pageRowImage {
    width: 100%;
    text-align: center;

  }
  .projectDescription {
        font-size: 20px;
        width: 100%;
        padding: 10px;
}
  .pageRowTitle {
    text-align: center;
    font-size: 1.1rem;
}
.rowHeadingTagline {
    font-size: 1rem;
    padding-top: 30px;
}
}
    